import { TOGGLE_MENU } from "../actions/types"

const init_state = false

export default (state = init_state, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return !state;
    default:
      return state;
  }
};