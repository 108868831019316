import products from "../../resources/products.json"
import { SET_PRODUCTS } from "../actions/types"

const init_state = products.items

export default (state = init_state, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};