import products from "../../resources/products.json"
import { SET_CATEGORIES } from "../actions/types"

const init_state = products.categories

export default (state = init_state, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};