import { applyMiddleware, createStore } from "redux"
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import  productsMiddleware  from "./middlewares/productsMiddleware"

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['categories','products','isMenuButtonOpen'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(productsMiddleware)));
  const persistor = persistStore(store);
  return { store, persistor };
};