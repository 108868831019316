import React from "react"
import { Provider } from "react-redux"
import {PersistGate} from 'redux-persist/integration/react';
import store from "./store"

export default ({element}) => (
  <Provider store={store().store}>
    <PersistGate loading={null} persistor={store().persistor}>
      {element}
    </PersistGate>
  </Provider>
);