import { combineReducers } from "redux"
import categoryReducer from "./categoryReducer"
import productReducer from "./productsReducer"
import menuButtonReducer from "./menuButtonReducer"

const rootReducer = combineReducers({
  categories: categoryReducer,
  products: productReducer,
  isMenuButtonOpen: menuButtonReducer
});

export default rootReducer;